import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import { Heading3, Heading4 } from '../Headings';
import Button from '../Button';
import Container from '../Container';

// #region styles
const SMission = styled.section`
  margin-bottom: 10px;

  ${mq.tablet} {
    margin-bottom: 60px;
  }

  ${mq.landscape} {
    margin-bottom: 110px;
  }
`;

const SIntro = styled.div`
  padding: 40px 30px 60px;
  background-color: ${COLORS.LIGHT};

  ${mq.tablet} {
    padding: 40px 60px 60px;
    background-color: transparent;
  }

  ${mq.landscape} {
    padding: 60px 120px 110px;
  }

  ${mq.desktop} {
    padding: 80px 240px;
  }
`;

const SIntroDescription = styled.div`
  margin-top: 2em;
`;

const SDecoratedWrapper = styled.div`
  position: relative;

  ${mq.tablet} {
    display: flex;
    align-items: center;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 13px;
    background-color: ${COLORS.PRIMARY};

    ${mq.tablet} {
      top: -17px;
      bottom: -17px;
    }
  }

  &:before {
    left: 0;
  }

  &:after {
    display: none;
    right: 0;

    ${mq.landscape} {
      display: block;
    }
  }
`;

const SPhoto = styled.img`
  width: 100%;

  ${mq.tablet} {
    width: 35%;
    object-fit: contain;
  }

  ${mq.landscape} {
    width: 40%;
  }
`;

const SContent = styled.div`
  padding: 40px 30px 80px;

  ${mq.tablet} {
    padding: 0 30px;
  }

  ${mq.landscape} {
    padding: 40px 60px 80px;
    max-width: 505px;
    padding: 0 50px;
  }

  ${mq.desktop} {
    max-width: 575px;
  }
`;

const SDescription = styled.div`
  margin-top: 15px;
  white-space: pre-line;

  ${mq.tablet} {
    font-size: 13px;
  }

  ${mq.landscape} {
    font-size: 14px;
  }
`;

const SWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.tablet} {
    align-items: flex-start;
  }

  ${mq.landscape} {
    align-items: flex-start;
  }
`;

const CButton = css`
  margin-top: 50px;

  ${mq.tablet} {
    margin-top: 20px;
  }

  ${mq.landscape} {
    margin-top: 50px;
  }
`;

const SHeart = styled.img`
  margin-top: 50px;

  ${mq.tablet} {
    display: none;
  }

  ${mq.landscape} {
    display: block;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0;
    transform: translateX(50%) translateY(-40%);
  }

  ${mq.desktop} {
    transform: translateX(75%) translateY(-40%);
  }
`;

// endregion

const Mission = ({ header, description, mission }) => (
  <Container>
    <SMission>
      <SIntro>
        <Heading4>{header}</Heading4>
        <SIntroDescription>{description}</SIntroDescription>
      </SIntro>
      <SDecoratedWrapper>
        {mission.imageUrl && (
          <SPhoto src={mission.imageUrl} alt={mission.imageAlt} />
        )}
        <SContent>
          <Heading3>{mission.title}</Heading3>
          <SDescription>{mission.description}</SDescription>
          <SWrapper>
            {mission.button.url && (
              <OutboundLink
                href={mission.button.url}
                target={mission.button.newTab ? '_blank' : null}
              >
                <Button cssStyle={CButton}>{mission.button.text}</Button>
              </OutboundLink>
            )}

            <SHeart src="../images/3-HeartIcon.png" alt="Heart icon" />
          </SWrapper>
        </SContent>
      </SDecoratedWrapper>
    </SMission>
  </Container>
);

export default Mission;
