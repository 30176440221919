import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import { Heading3 } from '../Headings';
import Container from '../Container';
import Background from '../Background';

// #region styles
const STeam = styled.section`
  position: relative;
  padding: 130px 30px 80px;
  background: ${COLORS.LIGHT};

  ${mq.tablet} {
    padding: 130px 120px 80px 120px;
  }

  ${mq.desktop} {
    padding: 130px 180px 80px 240px;
  }
`;

const STopDecorator = styled(Background)`
  position: absolute;
  top: 35px;
  left: 50%;
  width: 100vw;
  height: 75px;
  background-size: auto 275%;
  background-position: 90% top;
  background-repeat: repeat-x;
  transform: translateX(-50%);

  ${mq.desktop} {
    width: 90%;
    height: 100px;
    background-position: right top;
    background-size: auto 150%;
  }
`;

const SSideDecorator = styled(Background)`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    top: 100px;
    left: 10px;
    bottom: 0;
    width: 100px;
    background-size: contain;
    background-position: center top;
    background-repeat: repeat-y;
  }

  ${mq.desktop} {
    left: 120px;
  }
`;

const SDescription = styled.p`
  margin-top: 20px;
  color: ${COLORS.PRIMARY};
  font-size: 16px;
  font-weight: bold;

  ${mq.desktop} {
    font-size: 20px;
  }
`;

const STeamList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const STeamMember = styled.li`
  ${mq.tablet} {
    display: flex;
    margin-top: 70px;
  }
`;

const SPhoto = styled.div`
  display: block;
  margin-top: 40px;

  ${mq.tablet} {
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 30px;
  }
`;

const SContent = styled.div``;

const SImage = styled.img`
  width: 100%;
  max-width: 100%;

  ${mq.largePhone} {
    max-width: 410px;
  }

  ${mq.tablet} {
    width: 185px;
  }
`;

const SName = styled(Heading3)`
  margin-top: 15px;

  ${mq.tablet} {
    margin-top: 0;
    font-size: 16px;
  }

  ${mq.desktop} {
    font-size: 20px;
  }
`;

const SPosition = styled.div`
  margin-top: 5px;
  font-size: 16px;
  font-style: italic;

  ${mq.tablet} {
    font-size: 13px;
  }

  ${mq.desktop} {
    font-size: 16px;
  }
`;

const SBio = styled.div`
  margin-top: 15px;

  p {
    margin-top: 1.5em;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;
// endregion

const Team = ({ title, description, members }) => {
  return (
    <Container>
      <STeam>
        <STopDecorator src="../images/4-DataPattern.png" />
        <SSideDecorator src="../images/5-DataPattern.png" />

        <Heading3>{title}</Heading3>
        {description && <SDescription>{description}</SDescription>}

        <STeamList>
          {members.map((item, index) => (
            <STeamMember key={index}>
              {item.image?.publicURL && (
                <SPhoto>
                  {item.linkedInUrl ? (
                    <OutboundLink href={item.linkedInUrl} target="_blank">
                      <SImage src={item.image.publicURL} alt={item.name} />
                    </OutboundLink>
                  ) : (
                    <SImage src={item.image.publicURL} alt={item.name} />
                  )}
                </SPhoto>
              )}
              <SContent>
                <SName>{item.name}</SName>
                {item.title && <SPosition>{item.title}</SPosition>}
                {item.description.html && (
                  <SBio
                    dangerouslySetInnerHTML={{ __html: item.description.html }}
                  />
                )}
              </SContent>
            </STeamMember>
          ))}
        </STeamList>
      </STeam>
    </Container>
  );
};

export default Team;
