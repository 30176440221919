import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import { Heading3 } from '../Headings';
import Container from '../Container';
import Pill from '../Pill';

// #region styles
const SBoard = styled.section`
  position: relative;
  padding: 80px 30px 80px;

  ${mq.landscape} {
    padding: 130px 60px 80px 120px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 130px;
      bottom: 80px;
      width: 13px;
      background-color: ${COLORS.PRIMARY};
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  ${mq.desktop} {
    padding: 130px 180px 80px 240px;
  }
`;

const SDescription = styled.p`
  margin-top: 20px;
`;

const SBoardList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${mq.largePhone} {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  ${mq.tablet} {
    margin-left: -20px;
    margin-right: -20px;
  }

  ${mq.landscape} {
    margin-left: -40px;
    margin-right: -40px;
  }

  ${mq.desktop} {
    margin-left: -35px;
    margin-right: -35px;
  }
`;

const SBoardMember = styled.li`
  ${mq.largePhone} {
    margin-left: 10px;
    margin-right: 10px;
  }

  ${mq.tablet} {
    margin-left: 15px;
    margin-right: 15px;
  }

  ${mq.landscape} {
    margin-left: 40px;
    margin-right: 40px;
  }

  ${mq.desktop} {
    margin-left: 35px;
    margin-right: 35px;
  }
`;

const SPhoto = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 40px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 13px;
    background-color: ${COLORS.PRIMARY};
  }
`;

const SContent = styled.div`
  &:first-of-type {
    margin-top: 40px;
  }
`;

const SImage = styled.img`
  display: block;
  width: 175px;
  max-height: 175px;
  max-width: 100%;
  object-fit: cover;
`;

const SName = styled(Heading3)`
  margin-top: 15px;

  ${mq.tablet} {
    font-size: 16px;
  }

  ${mq.desktop} {
    font-size: 20px;
  }
`;

const SShortDescription = styled.div`
  margin-top: 5px;
  max-width: 200px;
`;

const SPill = styled(Pill)`
  position: absolute;
  left: 30px;
  top: 0;
  transform: translateY(-50%);

  ${mq.landscape} {
    left: 120px;
  }

  ${mq.desktop} {
    left: 240px;
  }
`;
// endregion

const Board = ({ title, description, members }) => (
  <Container>
    <SBoard>
      <SPill colors={[COLORS.PRIMARY, COLORS.LIGHT]} />

      <Heading3>{title}</Heading3>
      {description && <SDescription>{description}</SDescription>}

      <SBoardList>
        {members.map((item, index) => (
          <SBoardMember key={index}>
            {item.image?.publicURL && (
              <SPhoto>
                {item.linkedInUrl ? (
                  <OutboundLink href={item.linkedInUrl} target="_blank">
                    <SImage src={item.image?.publicURL} alt={item.name} />
                  </OutboundLink>
                ) : (
                  <SImage src={item.image?.publicURL} alt={item.name} />
                )}
              </SPhoto>
            )}
            <SContent>
              <SName>{item.name}</SName>
              {item.title && (
                <SShortDescription>{item.title}</SShortDescription>
              )}
            </SContent>
          </SBoardMember>
        ))}
      </SBoardList>
    </SBoard>
  </Container>
);

export default Board;
