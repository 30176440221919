import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Mission from '../components/about/Mission';
import Team from '../components/about/Team';
import Board from '../components/about/Board';
import Logos from '../components/Logos';

const AboutPage = ({ data }) => {
  const yamlData = data.aboutYaml;
  const { meta, hero, mission, executiveTeam, advisoryBoard, featuredIn } =
    yamlData;

  return (
    <Layout meta={meta}>
      <Hero
        title={hero.title}
        description={hero.description}
        image={hero.image?.publicURL}
        imageAlt={hero.imageAlt}
        decorationImage={hero.decorationImage?.publicURL}
        decorationImageAlt={hero.decorationImageAlt}
      />

      <Mission
        header={mission.introHeader}
        description={mission.introDescription}
        mission={{
          title: mission.title,
          description: mission.description,
          imageUrl: mission.image?.publicURL,
          imageAlt: mission.imageAlt,
          button: mission.button,
        }}
      />

      <Team
        title={executiveTeam.title}
        description={executiveTeam.description}
        members={executiveTeam.members}
      />

      <Board
        title={advisoryBoard.title}
        description={advisoryBoard.description}
        members={advisoryBoard.members}
      />

      <Logos title="FEATURED IN" items={featuredIn} bgColor="#E6E6E6" />
    </Layout>
  );
};

export default AboutPage;

// #region graphql
export const pageQuery = graphql`
  query AboutPage {
    aboutYaml {
      meta {
        title
        description
      }
      hero {
        title
        description
        image {
          publicURL
        }
        imageAlt
        decorationImage {
          publicURL
        }
        decorationImageAlt
      }
      mission {
        introHeader
        introDescription
        header
        description
        image {
          publicURL
        }
        imageAlt
        title
        button {
          url
          text
          newTab
        }
      }
      executiveTeam {
        title
        description
        members {
          name
          title
          image {
            publicURL
          }
          description {
            html
          }
          linkedInUrl
        }
      }
      advisoryBoard {
        title
        description
        members {
          name
          title
          linkedInUrl
          image {
            publicURL
          }
        }
      }
      featuredIn {
        name
        image {
          publicURL
        }
        url
        newTab
      }
    }
  }
`;
// endregion
