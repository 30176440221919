import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

const backgroundOptions = {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'src',
};

const SBackground = styled('div', backgroundOptions)`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src || ''});
  background-size: ${({ size }) => size || 'cover'};
  background-position: ${({ position }) => position || 'center center'};
  background-repeat: no-repeat;
`;

export default SBackground;
