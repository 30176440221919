import React from 'react';

import styled from '@emotion/styled';
import { mq } from '../styles/functions/mq';

import Container from './Container';
import { HeadingMinor } from './Headings';
import SmartLink from './SmartLink';

// #region styles
const SLogos = styled.section`
  padding: 80px 30px;

  ${mq.tablet} {
    padding: 80px 60px 120px;
  }

  ${mq.landscape} {
    padding: 80px 120px 120px;
  }

  ${mq.desktop} {
    padding: 80px 240px 160px;
  }
`;

const SHeadingMinor = styled(HeadingMinor)`
  ${mq.tablet} {
    margin-bottom: 100px;
  }
`;

const SList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.landscape} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SLogo = styled.div`
  display: inline-block;
  width: 65%;
  max-width: 200px;
  padding: 35px 0;
  text-align: center;

  &:nth-of-type(2n + 1) {
    width: 50%;

    ${mq.landscape} {
      width: auto;
    }
  }

  ${mq.landscape} {
    width: auto;
    max-width: none;
    min-width: 110px;
    padding: 0 20px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const SLogoImg = styled.img`
  max-height: 80px;
  margin: auto;
  object-fit: contain;

  ${mq.landscape} {
    max-height: 60px;
  }
`;

// endregion

const Logos = ({ title, items, bgColor }) => {
  if (items.length === 0) return null;

  return (
    <Container>
      <SLogos style={{ backgroundColor: bgColor || 'none' }}>
        <SHeadingMinor>{title}</SHeadingMinor>

        <SList>
          {items.map((item, index) => (
            <SLogo key={index}>
              {item.url ? (
                <SmartLink
                  url={item.url}
                  newTab={item.newTab}
                  outboundAppend="utm_source=litmus&utm_medium=homepage&utm_campaign=press_links"
                >
                  <SLogoImg src={item.image.publicURL} alt={item.name} />
                </SmartLink>
              ) : (
                <SLogoImg src={item.image.publicURL} alt={item.name} />
              )}
            </SLogo>
          ))}
        </SList>
      </SLogos>
    </Container>
  );
};

export default Logos;
